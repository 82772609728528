import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);
import anime from 'animejs/lib/anime.es.js';

export function observeFunc() {
  const clientHeight = document.documentElement.clientHeight;
  const header = document.querySelector('#header');
  let option = {
    root: document.querySelector("#observerArea"),
    // 上下100px、左右20px手前で発火
    rootMargin: "100px 20px",
    // 交差領域が50%変化するたびに発火
    threshold: [0, 0.5, 1.0]
  };
  let observer = new IntersectionObserver((changes) => {
    for (let change of changes) {
      let rect = change.target.getBoundingClientRect();
      let h = (0 < rect.top && rect.top < clientHeight) // 対象の上端は表示領域に入っている
        ||
        (0 < rect.bottom && rect.bottom < clientHeight) // 対象の下端は表示領域に入っている
        ||
        (0 > rect.top && rect.bottom > clientHeight); // 上端下端も表示されてないがその間が表示されている
      if (h) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    }
  }, option);
  let target = document.querySelector('.xyz');
  observer.observe(target);
}

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function sticyFunc() {
  // position: stickyがブラウザで使えるかチェックするための関数
  function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    return div.style.position.indexOf('sticky') !== -1;
  }
  // もしブラウザでposition: stickyが使えなければ、callStickyState関数を呼び出す
  if (!detectSticky()) {
    let jsSideNav = $('#pageTop');
    Stickyfill.add(jsSideNav);
  }
}

export function waitFunc() {
  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  wait(1000)
    .then(() => {
      console.log(1)
      return wait(1000)
    }).then(() => {
      console.log(2)
      return wait(1000)
    }).then(() => {
      console.log(3)
    })
}

export function slowlyScroll(){
  // function smoothScroll(toBottom) {
  //   let direction = "-=400";
  //   if (toBottom) {
  //     direction = "+=400";
  //   }
  //   gsap.to(window, {
  //     scrollTo: { y: direction, autoKill: true },
  //     duration: 1,
  //   });
  // }
  
  // let startY;
  // function touchstart(e) {
  //   startY = e.changedTouches[0].pageY;
  // }
  // function touchmove(e) {
  //   e.preventDefault();
  //   const moveY = e.changedTouches[0].pageY;
  //   if (moveY < startY) {
  //     smoothScroll(true);
  //   } else {
  //     smoothScroll(false);
  //   }
  // }
  // function mousemove(e) {
  //   e.preventDefault();
  //   if (0 < e.deltaY) {
  //     smoothScroll(true);
  //   } else {
  //     smoothScroll(false);
  //   }
  // }
  // document.addEventListener("touchstart", touchstart, { passive: false });
  // document.addEventListener("touchmove", touchmove, { passive: false });
  // document.addEventListener("mousewheel", mousemove, { passive: false });
}

export function fadeInFunc() {
  // const options = {
  //   root: null,
  //   threshold: 0
  // };

  // const callback = (function (changes) {
  //   for (let change of changes) {
  //     let target = change.target;
  //     if (change.isIntersecting) {
  //       target.classList.add('active');
  //     }
  //   }
  // });

  // const observer = new IntersectionObserver(callback, options);

  // const targets = document.querySelectorAll('.fadeIn')
  // for (let target of targets) {
  //   observer.observe(target)
  // }

  let bottomPage = $('#bottom').length;

  let scrollFadeInFunc = function() {
    $('.fadeIn').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      
      if (scroll > position - windowHeight + 100) {
        $(this).addClass('active');
      }
    });
  }

  if (bottomPage) {
    scrollFadeInFunc();
    $(window).on('scroll', function () {
      scrollFadeInFunc();
    });
  }
}

export function bottomMainviewFunc(){
  let bottomPage = $('#bottom').length;

  if (bottomPage) {
    // anime({
    //   targets: '.onAirTit path',
    //   strokeDashoffset: [anime.setDashoffset, 0],
    //   stroke: '#e0ecf4',
    //   easing: 'easeInOutSine',
    //   duration: 3000,
    // });
  }
}