//トップページ　横スクロール
import gsap from 'gsap';
import {
  ScrollTrigger,
  ScrollToPlugin
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import anime from 'animejs/lib/anime.es.js';

import 'jquery.easing';

import 'jquery.cookie';

import {
  no_scroll,
  return_scroll
} from '../common/_common';

export function loadingFunc() {
  // $('#loaderBg').delay(500).fadeOut(500);
  // $('#loader').fadeOut(500);
  
  $('#loaderBg').fadeOut(600, function() {
    $('#loader').delay(200).fadeOut();
  });
}

export function idxFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {

    const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));

    var animateMainviewFlag01 = false;
    var animateMainviewFlag02 = false;
    var animateMainviewFlag03 = false;
    var animateMainviewFlag04 = false;
    var animateMainviewFlag05 = false;
    var animateMainviewFlag06 = false;

    var animateMainviewScrollFlag01 = false;
    var animateMainviewScrollFlag02 = false;
    var animateMainviewScrollFlag03 = false;
    var animateMainviewScrollFlag04 = false;
    var animateMainviewScrollFlag05 = false;
    var animateMainviewScrollFlag06 = false;

    const navBtn = $('#navBtn');
    const header = $('#header');

    let resizecheck = $('#responsibleCheck').width();

    function doReloadWithCache() {
      // キャッシュを利用してリロード
      window.location.reload(false);
    }
    function orientationCheck() {
      var w = $(window).width();
      var h = $(window).height();
      if(w < h) {
          return 'portrait';
      } else {
          return 'landscape';
      }
    }

    var current = orientationCheck(); // 現在の端末の向きを保存
 
    $(window).on('resize', function() {
        // 端末の向きが変更されたとき
        if(current != orientationCheck()) {
            current = orientationCheck(); // 端末の向きを更新
            doReloadWithCache();
        }
    });

    function smoothScroll(toBottom) {
      let direction,easing,duration,autoKillvalue;
      var ua = window.navigator.userAgent.toLowerCase();

      if(resizecheck == 2){
        direction = "-=150";
        easing = "steps(60)";
        duration = .4;
        autoKillvalue = false;
        if (toBottom) {
          direction = "+=150";
        }
      } else if (ua.indexOf("ipad") > -1 || (ua.indexOf("macintosh") > -1 && "ontouchend" in document) || resizecheck == 3) {
        direction = "-=150";
        easing = "steps(60)";
        duration = .4;
        autoKillvalue = true;
        if (toBottom) {
          direction = "+=150";
        }
      } else {
        direction = "-=700";
        easing = "steps(40)";
        duration = 1;
        autoKillvalue = true;
        if (toBottom) {
          direction = "+=700";
        }
      }
      gsap.to(window, {
        scrollTo: { y: direction, autoKill: autoKillvalue, invalidateOnRefresh: true, },
        duration: duration,
        ease: easing,
      });
    }
    
    let startY, scrTop, mainviewHeight
    function touchstart(e) {
      startY = e.changedTouches[0].pageY;
      scrTop = $(window).scrollTop();
      mainviewHeight = $('#mainview').innerHeight();

      direction = "0";
          
      if (scrTop < mainviewHeight) {
        e.preventDefault();
      }   
    }
    function touchmove(e) {
      e.preventDefault();
      const moveY = e.changedTouches[0].pageY;
      if (moveY < startY) {
        smoothScroll(true);
      } else {
        smoothScroll(false);
      }
    }
    function mousemove(e) {
      e.preventDefault();
      if (0 < e.deltaY) {
        smoothScroll(true);
      } else {
        smoothScroll(false);
      }
    }

    if (!$('#seen01').is(':animated')) {
      wait(0)
        .then(() => {
          $('#seen01').addClass('isShow');
          return wait(1100)
        })
        .then(() => {
          $('#seen01 .mainviewTit').addClass('isShow');
          return wait(1100)
        })
        .then(() => {
          $('#seen01 .move01').stop(true, true).animate({
            opacity: 1,
            left: 0,
            top: 0,
          }, {
            duration: 1000,
            queue: false,
            complete: function () {
              $('#seen01 .move01').addClass('isShow');
            }
          });
          $('#seen01 .move02').stop(true, true).animate({
            opacity: 1,
            left: 0,
            top: 0,
          }, {
            duration: 1000,
            queue: false,
            complete: function () {
              $('#seen01 .move02').addClass('isShow');
            }
          });
          $('#seen01 .move03').stop(true, true).animate({
            opacity: 1,
            right: 0,
            top: 0,
          }, {
            duration: 1000,
            queue: false,
            complete: function () {
              $('#seen01 .move03').addClass('isShow');
            }
          });
          $('#seen01 .move04').stop(true, true).animate({
            opacity: 1,
            right: 0,
            bottom: 0,
          }, {
            duration: 1000,
            queue: false,
            complete: function () {
              $('#seen01 .move04').addClass('isShow');
            }
          });
          $('#seen01 .move05').stop(true, true).animate({
            opacity: 1,
            left: 0,
            bottom: 0,
          }, {
            duration: 1000,
            queue: false,
            complete: function () {
              $("#mainviewScroll").addClass('isShow');
              $('#seen01 .move05').addClass('isShow').delay(500).queue(function(){
                animateMainviewFlag01 = true;
              });
            }
          });
          return wait(0)
        })
    }

    function mainviewAnimeFunc() {
      if(!animateMainviewScrollFlag01 && animateMainviewFlag01){
        animateMainviewScrollFlag01 = true;

        if (!$('#commonSeen').is(':animated') && animateMainviewFlag01) {
          $('#seen01').removeClass('isShow');
          $("#mainviewScroll").addClass('isGradation');
          $("#commonSeen").addClass('isShow').delay(1000).queue(function () {
            $('#seen02').animate({
              opacity: 1,
            }, 1000 , function () {
              $('#seen02 .imgFade').animate({
                opacity: 1,
              }, 600, function () {
                $("#seen02 .titAnime").css({transform: "scale(1)"}).delay(1000).queue(function(){
                  animateMainviewFlag02 = true;
                });
              });
            });
          });
        }
      }

      if(!animateMainviewScrollFlag02 && animateMainviewFlag02){
        animateMainviewScrollFlag02 = true;

        if (!$('#seen03').is(':animated') && animateMainviewFlag02) {
          $('#seen02').animate({
            opacity: 0,
          }, 500, function () {
            $('#seen03').animate({
              opacity: 1,
            }, 1000, function () {
              $('#seen03 .imgFade').animate({
                opacity: 1,
              }, 600, function () {
                $("#seen03 .titAnime").css({transform: "scale(1)"}).delay(1000).queue(function(){
                  animateMainviewFlag03 = true;
                });
              });
            });
          });
        }
      }
      
      if(!animateMainviewScrollFlag03 && animateMainviewFlag03){
        animateMainviewScrollFlag03 = true;

        if (!$('#seen04').is(':animated') && animateMainviewFlag03) {
          $('#seen03').animate({
            opacity: 0,
          }, 500, function () {
            $('#seen04').animate({
              opacity: 1,
            }, 1000, function () {
              $('#seen04 .imgFade').animate({
                opacity: 1,
              }, 600, function () {
                $("#seen04 .titAnime").css({transform: "scale(1)"}).delay(1400).queue(function(){
                  animateMainviewFlag06 = true;
                });
              });
            });
          });
        }
      }

      if(!animateMainviewScrollFlag06 && animateMainviewFlag06){
        animateMainviewScrollFlag06 = true;

        $('body').removeClass('fixedBody').css({
          'top': 0
        });
        navBtn.addClass('isShow');
        header.addClass('isShow');

        var inertiaScrollingPage = document.getElementById('inertiaScrolling');

        inertiaScrollingPage.addEventListener("wheel", mousemove, { passive: false });

        inertiaScrollingPage.addEventListener("touchstart", touchstart, { passive: false });
        inertiaScrollingPage.addEventListener("touchmove", touchmove, { passive: false });

        // var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
          // var before_delta_minus = 0
          // var before_delta_plus = 0
          // $(document).on(mousewheelevent,function(e){
          //   var delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);
          //   if (delta < 0 && delta < before_delta_minus){
          //     before_delta_minus = delta
          //     smoothScroll(true);
          //     // 下スクロールした際の挙動
          //   } else if(delta > 0 && delta > before_delta_plus){
          //       before_delta_plus = delta
          //       smoothScroll(true);
          //       // 上スクロールした際の挙動
          //   }
          // });
          // setInterval(function(){
          //   before_delta_minus = 0
          //   before_delta_plus = 0
          // },600)

        $.cookie("mainviewAnime", "mainviewAnime");
      }
      
    }

    if ($.cookie("mainviewAnime") == "mainviewAnime") {
      console.log('cookie on');

      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      wait(800)
        .then(() => {
            navBtn.addClass('isShow');
            header.addClass('isShow');
          return wait(1100)
        })

      var inertiaScrollingPage = document.getElementById('inertiaScrolling');

      inertiaScrollingPage.addEventListener("wheel", mousemove, { passive: false });
      inertiaScrollingPage.addEventListener("touchstart", touchstart, { passive: false });
      inertiaScrollingPage.addEventListener("touchmove", touchmove, { passive: false });
    }
    if ($.cookie("mainviewAnime") == undefined) {
      console.log('cookie none');

      $(window).on('wheel', function () {
        mainviewAnimeFunc();
      });
      $(window).on('touchmove', function () {
        mainviewAnimeFunc();
      });
      $(window).on('click', function () {
        mainviewAnimeFunc();
      });
    } 

    $.cookie('mainviewAnime', 'mainviewAnime', {
      path: '/',
      domain:'.ripple.co.jp'
    }); 


    //on air
    function onAirSvgAnime(){
      anime({
        targets: '.onAirTit path',
        strokeDashoffset: [anime.setDashoffset, 0],
        stroke: '#e0ecf4',
        easing: 'easeInOutSine',
        duration: 3000,
      });
    }
    const listWrapperEl = document.querySelector('.onAirProgramListWrap');
    const listEl = document.querySelector('.onAirProgramList');

    const onAirTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".onAir",
        start: "30% bottom",
        scrub: .3,
        invalidateOnRefresh: true,
      },
    });
    //スクロール時のアニメーション
    onAirTl.fromTo(
      ".onAirTit", {
        autoAlpha: 0,
        duration: .4,
      }, {
        autoAlpha: 1,
        ease: "Power4.out",
        onStart: () => { onAirSvgAnime(); },
      }
    );
    onAirTl.to(".fadeOnAir", {
      autoAlpha: 1,
      y: 0,
      duration: .8,
    }, "<");
    onAirTl.fromTo(
      listEl, {
        autoAlpha: 0,
        duration: .4,
      }, {
        autoAlpha: 1,
      }, "<"
    );
    onAirTl.to(listEl, {
      x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
      ease: 'none',
      scrollTrigger: {
        trigger: ".onAir",
        start: "top top",
        end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
        scrub: 3,
        pin: true,
        //anticipatePin: true,
        invalidateOnRefresh: true,
        pinnedContainer: '#inertiaScrolling',
      },
    });


    //about
    function aboutSvgAnime(){
      anime({
        targets: '.aboutUsTit path',
        strokeDashoffset: [anime.setDashoffset, 0],
        stroke: '#333',
        easing: 'easeInOutSine',
        duration: 2000,
      });
    }

    ScrollTrigger.matchMedia({
      // 1025px以上
      "(min-width: 1025px)": function() {
        const aboutUsTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".aboutUs",
            start: "top top",
            scrub: 2.5,
            // .top要素が固定される
            pin: true,
          },
        });
        // スクロール時のアニメーション
        aboutUsTl.fromTo(
          ".aboutUs .imgBox", {
            x: -innerWidth / 6,
            scale: 1.4,
            delay: 2,
          }, {
            x: 1,
            scale: 1,
            ease: "Power4.out",
            onComplete: () => { 
              aboutSvgAnime();
              $('.aboutUs .imgBox').addClass('anime');
            },
          }
        );
        aboutUsTl.to(".aboutUsTit", {
          autoAlpha: 1,
          duration: 1,
        });
        aboutUsTl.to(".fadeAbout", {
          autoAlpha: 1,
          y: 0,
          duration: .8,
        }, "<");
        aboutUsTl.to(".fadeAbout", {
          y: 0,
        }, "<6");
      },
      // 1024px以上768px以下
      "(min-width: 961px) and (max-width: 1024px)": function() {
        const aboutUsTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".aboutUs",
            start: "top top",
            scrub: 2.5,
            // .top要素が固定される
            pin: true,
          },
        });
        // スクロール時のアニメーション
        aboutUsTl.fromTo(
          ".aboutUs .imgBox", {
            x: -innerWidth / 7,
            scale: 1.4,
            delay: 2,
          }, {
            x: 1,
            scale: 1,
            ease: "Power4.out",
            onComplete: () => { 
              aboutSvgAnime();
              $('.aboutUs .imgBox').addClass('anime');
            },
          }
        );
        aboutUsTl.to(".aboutUsTit", {
          autoAlpha: 1,
          duration: 1,
        });
        aboutUsTl.to(".fadeAbout", {
          autoAlpha: 1,
          y: 0,
          duration: .8,
        }, "<");
        aboutUsTl.to(".fadeAbout", {
          y: 0,
        }, "<6");
      },
      // 767px以下
      "(max-width: 960px)": function() {
        const aboutUsTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".aboutUs",
            start: "20% bottom",
            invalidateOnRefresh: true,
          },
        });
        // スクロール時のアニメーション
        aboutUsTl.to(".aboutUsTit", {
          autoAlpha: 1,
          duration: .5,
          onStart: () => { 
            aboutSvgAnime();
            $('.aboutUs .imgBox').addClass('anime');
          },
        });
        aboutUsTl.fromTo(
          ".aboutUs .imgBox", {
            delay: 2,
            duration: 3,
            autoAlpha: 0,
          }, {
            autoAlpha: 1,
            ease: "Power4.out",
          }
        );
      }
    }); 

    //recruit
    function recruitSvgAnime(){
      anime({
        targets: '.recruitTit path',
        strokeDashoffset: [anime.setDashoffset, 0],
        stroke: '#333',
        easing: 'easeInOutSine',
        duration: 2000,
      });
    }

    ScrollTrigger.matchMedia({
      // 1025px以上
      "(min-width: 1025px)": function() {
        const recruitTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".recruit",
            start: "top top",
            scrub: 2.5,
            // .top要素が固定される
            pin: true,
          },
        });
        // スクロール時のアニメーション
        recruitTl.fromTo(
          ".recruit .imgBox", {
            x: innerWidth / 6,
            scale: 1.4,
            delay: 2,
          }, {
            x: 1,
            scale: 1,
            ease: "Power4.out",
            onComplete: () => { 
              recruitSvgAnime();
              $('.recruit .imgBox').addClass('anime');
            },
          }
        );
        recruitTl.to(".recruitTit", {
          autoAlpha: 1,
          duration: 1,
        });
        recruitTl.to(".fadeRecruit", {
          autoAlpha: 1,
          y: 0,
          duration: .8,
        }, "<");
        recruitTl.to(".fadeAbout", {
          y: 0,
        }, "<6");
      },
      // 1024px以上768px以下
      "(min-width: 961px) and (max-width: 1024px)": function() {
        const recruitTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".recruit",
            start: "top top",
            scrub: 2.5,
            // .top要素が固定される
            pin: true,
          },
        });
        // スクロール時のアニメーション
        recruitTl.fromTo(
          ".recruit .imgBox", {
            x: innerWidth / 7,
            scale: 1.4,
            delay: 2,
          }, {
            x: 1,
            scale: 1,
            ease: "Power4.out",
            onComplete: () => { 
              recruitSvgAnime();
              $('.recruit .imgBox').addClass('anime');
            },
          }
        );
        recruitTl.to(".recruitTit", {
          autoAlpha: 1,
          duration: 1,
        });
        recruitTl.to(".fadeRecruit", {
          autoAlpha: 1,
          y: 0,
          duration: .8,
        }, "<");
        recruitTl.to(".fadeAbout", {
          y: 0,
        }, "<6");
      },
      // 767px以下
      "(max-width: 960px)": function() {
        const recruitTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".recruit",
            start: "20% bottom",
            invalidateOnRefresh: true,
          },
        });
        // スクロール時のアニメーション
        recruitTl.to(".recruitTit", {
          autoAlpha: 1,
          duration: .5,
          onStart: () => { 
            recruitSvgAnime();
            $('.recruit .imgBox').addClass('anime');
          },
        });
        recruitTl.fromTo(
          ".recruit .imgBox", {
            delay: 2,
            duration: 3,
            autoAlpha: 0,
          }, {
            autoAlpha: 1,
            ease: "Power4.out",
          }
        );
      }
    });
  }
}

export function idxScrFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    const mainview = document.querySelector('#mainview');
    const seen01 = document.querySelector('#seen01');
    const mainviewScroll = document.querySelector('#mainviewScroll');

    const callback = (function (changes) {
      for (let change of changes) {
        if (!change.isIntersecting) {
          mainview.classList.add('scrollDown');
          seen01.classList.add('isShow');
          mainviewScroll.classList.remove('isShow');
        }
      }
    });

    const observer = new IntersectionObserver(callback);
    const target = document.querySelector('#mainview');
    observer.observe(target);


    let recruit = document.querySelector('#recruit');
    let footer = document.querySelector('#footer');
    let breadcrumb = document.querySelector('#breadcrumb');
    const options = {
      rootMargin: '-40% 0px -40% 0px'
    };
    const callback02 = (function (changes) {
      for (let change of changes) {
        let target = change.target;
        if (change.isIntersecting) {
          recruit.classList.add('scrollDown');
          footer.classList.add('scrollDown');
          breadcrumb.classList.add('scrollDown');
          observer.unobserve(target);
        } else{
          recruit.classList.remove('scrollDown');
          footer.classList.remove('scrollDown');
          breadcrumb.classList.remove('scrollDown');
        }
      }
    });

    const observer02 = new IntersectionObserver(callback02, options);
    const target02 = document.querySelector('#footer');
    observer02.observe(target02);


    // const commonSeen = document.querySelector('#commonSeen');
    // const options03 = {
    //   rootMargin: '-40% 0px -40% 0px'
    // };
    // const callback03 = (function (changes) {
    //   for (let change of changes) {
    //     if (!change.isIntersecting) {
    //       commonSeen.classList.add('hide');
    //     }
    //   }
    // });

    // const observer03 = new IntersectionObserver(callback03, options03);
    // const target03 = document.querySelector('#mainview');
    // observer03.observe(target03);
  }
}